import React, { Component } from "react"
import Helmet from 'react-helmet'; 

// Common components & Layout 
import Layout from "../../layouts"; 
import { Container } from "react-bootstrap";

// Individual components that make up the page
import Newsletter from "../../components/newsletter/newsletter";
import Hero from "../../components/common/Hero"; 
import Section from "../../components/common/Section"; 
import SEO from "../../components/common/SEO"; 

class Privacy extends Component {
  render() {
      return (
          <Layout>            
            <SEO post={{
              title: "Facts First Privacy Policy",              
            }} />            
            <Helmet bodyAttributes={{ class: 'privacy-page' }} />            
            <Hero title='Privacy Policy'></Hero>
            <Container>       
            <Section name="privacy" header="Privacy Policy"/>                                       
            </Container>     
            <Newsletter />       
          </Layout>
      ); 
  }
}

export default Privacy; 